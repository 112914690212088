import "../styles/projectCards.css";
import "../styles/keyframes.css";
import ProjectPDF from "./projectPDF";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ProjectCards(props) {
  // The current open project
  const [openProject, setOpenProject] = useState({
    title: "Projects",
    pdfPath: "",
    redirect: "/projects/",
  });
  // Navigation tool
  const navigate = useNavigate();
  // URL parameters tool
  const [searchParams] = useSearchParams();

  // When we detect a url change or when we first load the page
  useEffect(() => {
    const project = searchParams.get("project");
    // If no url parameter is given, redirect to the base
    if (!project) {
      setOpenProject({
        title: props.projectsDict["base"]["title"],
        redirect: props.projectsDict["base"]["redirect"],
      });
      return;
    }
    // Loop through each project
    for (
      let index = 0;
      index < props.projectsDict["projects"].length;
      index++
    ) {
      // If the url parameter is a valid project title
      if (
        props.projectsDict["projects"][index]["title"].toLowerCase() === project
      ) {
        // Set the open project
        setOpenProject({
          title: props.projectsDict["projects"][index]["title"],
          pdfPath: props.projectsDict["projects"][index]["pdfPath"],
          redirect:
            props.projectsDict["redirect"] +
            props.projectsDict["projects"][index]["title"].toLowerCase(),
        });
      }
    }
  }, [window.location.href]);

  return (
    <div className="portfolio-container">
      {openProject && openProject["title"] === "Projects" && (
        <>
          <div className="portfolio-title">
            <div className="wave-text">
              <p>Projects</p>
              <p>Projects</p>
            </div>
            <p>Explore My Previous Projects</p>
          </div>
          <div className="portfolio-grid">
            {props.projectsDict["projects"].map((card, index) => {
              return (
                <div
                  className="card-container-animation"
                  key={index}
                  // If the page has refreshed, set the animation to none
                  // else set the opacity for 0 (used for the intro animation)
                  style={
                    !props.hasPageRefreshed
                      ? { opacity: 0 }
                      : { animation: "none", opacity: 1 }
                  }
                >
                  <div
                    className="card-container"
                    onClick={() => {
                      navigate(
                        props.projectsDict["redirect"] +
                          card["title"].toLowerCase()
                      );
                    }}
                  >
                    <div className="card-image">
                      <img src={card["image"]} alt="img"></img>
                    </div>
                    <div className="card-translate-container">
                      <div className="card-title">
                        <p>{card.title}</p>
                      </div>
                      <div className="card-description">
                        <p>{card.description}</p>
                      </div>
                      <div className="card-tools-container">
                        {card["tools"].map((tool, index) => {
                          return (
                            <p
                              key={index}
                              className={
                                index === card["tools"].length - 1
                                  ? ""
                                  : "card-tool-with-bullet"
                              }
                            >
                              {tool}
                            </p>
                          );
                        })}
                      </div>
                      <div className="link-container">
                        <a
                          href={card.link}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          <img
                            src={props.projectsDict["link-icon"]}
                            alt="img"
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {openProject && openProject["pdfPath"] && (
        <ProjectPDF path={openProject["pdfPath"]}></ProjectPDF>
      )}
    </div>
  );
}
